$(document).ready(function() {
    $('input').iCheck({
        checkboxClass: 'icheckbox_minimal-red',
        radioClass: 'iradio_minimal-red',
        increaseArea: '20%' // optional
    });
    $('.photo').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });
    var owl = $('.owl-head');
    owl.owlCarousel({
        loop: true,
        margin: 0,
        dots:true,
        nav: false,
        items: 1,
        autoplay:true,
        autoplayTimeout:5000
    });
    var services = $('.owl-services');
    services.owlCarousel({
        loop: true,
        margin: 0,
        nav: true,
        items: 6,
        autoplay:true,
        autoplayTimeout:5000,
        responsive:{
            0:{
                items:1
            },
            810:{
                items:3
            },
            1000:{
                items:4
            },
            1400:{
                items:6
            }
        },
        navText:['<i class="icon icon-arrow-right"></i>','<i class="icon icon-arrow-right"></i>'],
    });

    $('.product-gallery__small__gallery').owlCarousel({
        loop: false,
        margin: 16,
        items: 3,
        nav: true,
        navText:['<i class="icon icon-arrow-left"></i>','<i class="icon icon-arrow-right"></i>'],
    });

    $('.lazy').show().lazy();
    /* START menu for tablets and mobiles */
    $("#head a.mobile-btn").click(function() {
        if($('#head .nav').hasClass('active')){
            $('html').removeClass('has-open-menu');
            $('#head .nav').removeClass('active');
            $(this).removeClass('active');
            $(this).find('i.icon').addClass('icon-menu').removeClass('icon-close');
        }else{
            $('html').addClass('has-open-menu');
            $('#head .nav').addClass('active');
            $(this).addClass('active');
            $(this).find('i.icon').removeClass('icon-menu').addClass('icon-close');
        }
    });
    $('<li class="hidden-desktop"></li>').appendTo('#head .nav');
    $('.cta-buttons').clone().removeClass('cta-buttons--desktop').appendTo('#head .nav .hidden-desktop');
    $('.modal a.close-modal').click(function(){
        $($(this).attr('data-target')).removeClass('active');
    });
    $(function () {
        $("input,select,textarea").not("[type=submit]").jqBootstrapValidation({
            submitSuccess: function ($form, event) {
                if($( 'input[name="poukaz[]"]:checked' ).length == 0){
                    $('#alertOrder').addClass('active');
                    $([document.documentElement, document.body]).animate({
                        scrollTop: $("#alertOrder").offset().top
                    }, 1000);
                    event.preventDefault(event);
                }
            }
        });
    } );
    /* START objednavka poukazu */
    $(".button").on("click", function() {
        var $button = $(this);
        var oldValue = $button.parent().find("input").val();
        if ($button.text() == "+") {
            var newVal = parseFloat(oldValue) + 1;
        } else {
            // Don't allow decrementing below zero
            if (oldValue > 0) {
                var newVal = parseFloat(oldValue) - 1;
            } else {
                newVal = 0;
            }
        }
        $button.parent().find("input").val(newVal);
    });
    $('.offer-list input[type="checkbox"]').on('ifChecked', function(event){
        $('#pocet-'+$(this).val()).addClass('active');
    });
    $('.offer-list input[type="checkbox"]').on('ifUnchecked', function(event){
        $('#pocet-'+$(this).val()).removeClass('active');
    });
    /* END objednavka poukazu*/
});